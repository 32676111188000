*, ::before, ::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* TODO: need to prevent default fill browser autocomplete mb change important */
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #fff !important;
    -webkit-text-fill-color: unset;
}
